<template>
  <div class="cs-container">
    <div class="d-print-none mb-2 flex items-center justify-end" style="margin-right:80px">
      <b-button variant="primary" @click="handlePrint">In Phiếu</b-button>
    </div>

    <div class="d-flex align-items-center justify-content-center">
      <!-- <img class="cs-img col-6" src="@/assets/images/pages/AGTC-logo.png" /> -->
      <h2 class="text-center col-6 title-color">TEST REQUISITION FORM
        <br>
        <i class="title-color">Phiếu yêu cầu xét nghiệm</i>
      </h2>
      <span class="close-icon d-print-none" @click="backToDetail()">&times;</span>
    </div>

    <div id="printOrderForm" class="mt-3">
      <!-- PAGE 1 -->
      <b-row>
        <!-- COLUMN LEFT -->
        <b-col class="container-border">
          <!-- PATIENT INFORMATION -->
          <div>
            <span class="cs-title cs-border-b">PATIENT INFORMATION/ <i>Thông tin bệnh nhân</i></span>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Full name(as per IC/passport)
                <br>
                <i>Họ tên (theo IC/ hộ chiếu)</i>
              </span>
              <span class="cs-content" v-html="form.full_name"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">IC/passport number<br>
                <i>Số IC/hộ chiếu</i></span>
              <span class="cs-content" v-html="form.identity_card_number"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Medical Record No.
                <br>
                <i>Hồ sơ bệnh án số</i>
              </span>
              <span class="cs-content" v-html="form.medical_record_no"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Gender/ <i>Giới tính</i></span>
              <div class="d-flex cs-content">
                <div class="mr-1 cs-checkbox" :set="isMale=form.gender===1">
                  <input v-model="isMale" id="male" name="male" type="checkbox" />
                  <label>Male/ <i>Name</i></label>
                </div>
                <div class="mr-1 cs-checkbox" :set="isFemale=form.gender===2">
                  <input v-model="isFemale" id="female" name="female" type="checkbox" />
                  <label>Female/ <i>Nữ</i></label>
                </div>
              </div>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Urgency/ <i>Khẩn cấp</i></span>
              <div class="d-flex flex-column cs-content">
                <div class="mb-1 cs-checkbox" :set="isUrgency=form.urgency===1">
                  <input v-model="isUrgency" id="urgent" name="urgent" type="checkbox" />
                  <label>Urgent/ <i>Khẩn cấp</i> (TAT: 7 working days / <i>7 ngày làm việc</i>)</label>
                </div>
                <div class="cs-checkbox" :set="isNotUrgency=form.urgency===2">
                  <input v-model="isNotUrgency" id="notUrgent" name="notUrgent" type="checkbox" />
                  <label>Not Urgent/ <i>Không khẩn cấp</i> (TAT: 21 working days/ <i>21 ngày làm việc</i>)</label>
                </div>
              </div>
            </div>
            <div class="cs-flex">
              <span class="cs-label">
                Other specific instruction for
                laboratory services (if any)/
                <i>Các hướng dẫn cụ thể khác về dịch vụ xét nghiệm (nếu có)</i>
              </span>
              <span class="cs-content" v-html="form.other_instruction"></span>
            </div>
          </div>
          <!-- PATIENT INFORMATION -->
          <!-- REFERRING PHYSICIAN INFORMATION -->
          <div>
            <span class="cs-title cs-border-b cs-border-t">REFERRING PHYSICIAN INFORMATION/
              <i>THÔNG TIN BÁC SĨ GIỚI THIỆU</i>
            </span>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Name of Physician<br><i>Tên bác sĩ</i></span>
              <span class="cs-content" v-html="form.physician_name"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Contact Number<br><i>Số điện thoại liên hệ</i></span>
              <span class="cs-content" v-html="form.contact_number"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Email/ <i>Email</i></span>
              <span class="cs-content" v-html="form.email"></span>
            </div>
            <div class="cs-flex">
              <span class="cs-label">NSR No./ <i>Số NRS</i></span>
              <span class="cs-content" v-html="form.nsr_no"></span>
            </div>
          </div>
          <!-- REFERRING PHYSICIAN INFORMATION -->
          <!-- ACCOUNT INFORMATION -->
          <div>
            <span class="cs-title cs-border-b cs-border-t">ACCOUNT INFORMATION/ <i>THÔNG TIN TÀI KHOẢN</i></span>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Account Name<br><i>Tên tài khoản</i></span>
              <span class="cs-content" v-html="form.acc_name"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Contact No<br><i>Số liên hệ</i></span>
              <span class="cs-content" v-html="form.acc_contact_no"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Address/ <i>Địa chỉ</i></span>
              <span class="cs-content" v-html="form.acc_address"></span>
            </div>
            <div class="cs-flex">
              <span class="cs-label">Email/ <i>Email</i></span>
              <span class="cs-content" v-html="form.acc_email"></span>
            </div>
          </div>
          <!-- ACCOUNT INFORMATION -->
          <!-- BILLING INFORMATION -->
          <div>
            <span class="cs-title cs-border-b cs-border-t">BILLING INFORMATION/ <i>THÔNG TIN THANH TOÁN</i></span>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Paid by/ <i>Thanh toán</i></span>
              <div class="d-flex cs-content">
                <div class="mr-1 cs-checkbox" :set="isHospital=form.paid_by===1">
                  <input v-model="isHospital" id="hospital" name="hospital" type="checkbox" />
                  <label>Hospital/ <i>Bệnh viện</i></label>
                </div>
                <div class="mr-1 cs-checkbox" :set="isSelfPay= form.paid_by===2">
                  <input v-model="isSelfPay" id="self-pay" name="self-pay" type="checkbox" />
                  <label>Self-pay/ <i>Tự thanh toán</i></label>
                </div>
                <div class="mr-1 cs-checkbox" :set="isInsurance=form.paid_by===3">
                  <input v-model="isInsurance" id="insurance" name="insurance" type="checkbox" />
                  <label>Insurance/ <i>Bảo hiểm</i></label>
                </div>
              </div>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Billed To<br> <i>Thanh toán cho</i></span>
              <span class="cs-content" v-html="form.billed_to"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Account No.<br> <i>Số tài khoản</i></span>
              <span class="cs-content" v-html="form.bill_acc_no"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Billing address<br> <i>Địa chỉ thanh toán</i></span>
              <span class="cs-content" v-html="form.bill_address"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Phone/ <i>Điện thoại</i></span>
              <span class="cs-content" v-html="form.bill_phone"></span>
            </div>
            <div class="cs-flex">
              <span class="cs-label">Email/ <i>Email</i></span>
              <span class="cs-content" v-html="form.bill_email"></span>
            </div>
          </div>
          <!-- BILLING INFORMATION -->
          <!-- SPECIMEN INFORMATION -->
          <div>
            <span class="cs-title cs-border-b cs-border-t">SPECIMEN INFORMATION/ <i>THÔNG TIN MẪU</i></span>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Sample Collection Date & Time<br> <i>Ngày và thời gian lấy mẫu</i></span>
              <span class="cs-content" v-html="handleFormatDate(form.sample_collection_date_time)"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Sample/Block ID
                <br> <i>Mẫu/ID khối</i>
              </span>
              <span class="cs-content" v-html="form.sample_id"></span>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Sample Type/ <i>Loại mẫu</i></span>
              <div class="d-flex flex-column cs-content">
                <div class="mb-1 cs-checkbox" :set="isTumour= form.sample_type ===1">
                  <input
                    v-model="isTumour"
                    id="tumour-tissues"
                    name="tumour-tissues"
                    type="checkbox"
                  />
                  <label>Tumour Tissues/ <i>Mô khối u</i></label>
                </div>
                <div class="cs-checkbox" :set="isBlood= form.sample_type ===2">
                  <input v-model="isBlood" id="blood" name="blood" type="checkbox" />
                  <label>Blood/ <i>Máu</i></label>
                </div>
              </div>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">
                Fixative/Preservative (if
                applicable)
                <br><i>Chất định hình/bảo quản</i>
              </span>
              <div class="d-flex cs-content">
                <div class="mr-1 cs-checkbox" :set="isFixative= form.fixative ===1">
                  <input v-model="isFixative" id="formalin" name="formalin" type="checkbox" />
                  <label>10% Neutral Buffered Formalin<br><i>10% Formalin đệm trung tính</i></label>
                </div>

                <div class="mr-1 cs-checkbox" :set="isOtherFixative= form.fixative ===2">
                  <input
                    v-model="isOtherFixative"
                    id="others-formalin"
                    name="others-formalin"
                    type="checkbox"
                  />
                  <label>Others<br><i>Khác (Nếu có)</i></label>
                </div>
              </div>
            </div>
            <div class="cs-flex cs-border-b">
              <span class="cs-label">Body Site (if applicable)<br><i>Vị trí cơ thể (nếu có)</i></span>
              <span class="cs-content" v-html="form.body_site"></span>
            </div>
            <div class="cs-flex">
              <span class="cs-label">Official stamp of requesting account (Name, signature & date)<br><i>Con dấu chính thức của tài khoản yêu cầu (Tên, chữ ký & ngày)</i></span>
              <span class="cs-content" v-html="form.official_stamp"></span>
            </div>
          </div>
          <!-- SPECIMEN INFORMATION -->
        </b-col>

        <!-- COLUMN LEFT -->

        <!-- COLUMN RIGHT -->
        <b-col class="container-border ml-1">
          <!-- DIAGNOSIS/PATIENT HISTORY -->
          <div>
            <span class="cs-title cs-border-b">DIAGNOSIS/PATIENT HISTORY/ <i>CHẨN ĐOÁN/TIỀN SỬ BỆNH NHÂN</i></span>
            <span class="cs-title cs-border-b text-center">SOLID TUMOUR TYPE/ <i>LOẠI KHỐI U RẮN</i></span>

            <div class="cs-flex cs-border-b">
              <span class="cs-label">Solid Tumour Type<br><i>Loại khối u rắn</i></span>
              <div class="d-flex flex-column cs-content">
                <div class="cs-flex cs-content">
                  <div
                    class="cs-checkbox min-checkbox"
                    :set="isStomach= form.solid_tumour_type.some(item=>item===1)"
                  >
                    <input v-model="isStomach" id="stomach" name="stomach" type="checkbox" />
                    <label>Stomach/ <i>Bụng</i></label>
                  </div>
                  <div
                    class="cs-checkbox min-checkbox"
                    :set="isOesophageal= form.solid_tumour_type.some(item=>item===2)"
                  >
                    <input
                      v-model="isOesophageal"
                      id="oesophageal"
                      name="oesophageal"
                      type="checkbox"
                    />
                    <label>Oesophageal/ <i>Thực quản</i></label>
                  </div>
                  <div
                    class="cs-checkbox"
                    :set="isBreast= form.solid_tumour_type.some(item=>item===3)"
                  >
                    <input v-model="isBreast" id="breast" name="breast" type="checkbox" />
                    <label>Breast/ <i>Ngực</i></label>
                  </div>
                </div>
                <div class="cs-flex cs-content">
                  <div
                    class="cs-checkbox min-checkbox"
                    :set="isOvarian= form.solid_tumour_type.some(item=>item===4)"
                  >
                    <input v-model="isOvarian" id="ovarian" name="ovarian" type="checkbox" />
                    <label>Ovarian/ <i>U nang buồng trứng</i></label>
                  </div>
                  <div
                    class="cs-checkbox min-checkbox"
                    :set="isMelanoma= form.solid_tumour_type.some(item=>item===5)"
                  >
                    <input v-model="isMelanoma" id="melanoma" name="melanoma" type="checkbox" />
                    <label>Melanoma/ <i>Ung thư tế bào hắc tố</i></label>
                  </div>
                  <div
                    class="cs-checkbox"
                    :set="isBrain= form.solid_tumour_type.some(item=>item===6)"
                  >
                    <input v-model="isBrain" id="brain" name="brain" type="checkbox" />
                    <label>Brain/ <i>Não</i></label>
                  </div>
                </div>
                <div class="cs-flex cs-content">
                  <div
                    class="cs-checkbox min-checkbox"
                    :set="isProstate= form.solid_tumour_type.some(item=>item===7)"
                  >
                    <input v-model="isProstate" id="prostate" name="prostate" type="checkbox" />
                    <label>Prostate/ <i>Tuyến tiền liệt</i></label>
                  </div>
                  <div
                    class="cs-checkbox min-checkbox"
                    :set="isEndometrial= form.solid_tumour_type.some(item=>item===8)"
                  >
                    <input
                      v-model="isEndometrial"
                      id="endometrial"
                      name="endometrial"
                      type="checkbox"
                    />
                    <label>Endometrial/ <i>Lạc nội mạc tử cung</i></label>
                  </div>
                  <div
                    class="cs-checkbox"
                    :set="isLung= form.solid_tumour_type.some(item=>item===9)"
                  >
                    <input v-model="isLung" id="lung" name="lung" type="checkbox" />
                    <label>Lung/ <i>Phổi</i></label>
                  </div>
                </div>
                <div
                  class="cs-flex cs-checkbox"
                  :set="isSolidOthers= form.solid_tumour_type.some(item=>item===0)"
                >
                  <input
                    v-model="isSolidOthers"
                    id="solid-other"
                    name="solid-other"
                    type="checkbox"
                  />
                  <label>Others/ <i>Khác</i></label>
                </div>
              </div>
            </div>
          </div>
          <!-- PATIENT INFORMATION -->
          <!-- PATIENT INFORMATION STAGE -->
          <div class="cs-flex cs-border-b">
            <span class="cs-label">Stage/ <i>Giai đoạn</i></span>
            <div class="cs-content">
              <div class="cs-flex">
                <div
                  class="mb-1 cs-checkbox min-checkbox"
                  :set="isPrimary= form.solid_tumour_stage ===1"
                >
                  <input v-model="isPrimary" id="primary" name="primary" type="checkbox" />
                  <label>Primary/ <i>Nguyên phát</i></label>
                </div>
                <div
                  class="mb-1 cs-checkbox min-checkbox"
                  :set="isMetastasis= form.solid_tumour_stage ===2"
                >
                  <input v-model="isMetastasis" id="metastasis" name="metastasis" type="checkbox" />
                  <label>Metastasis/ <i>Di căn</i></label>
                </div>
              </div>
              <div class="cs-flex align-items-start">
                <span class="cs-label">If metastasis, list primary:<br> <i>Nếu có di căn, vui lòng liệt kê nguyên phát:</i></span>
                <span class="cs-content" v-html="form.list_primary"></span>
              </div>
              <div class="cs-flex">
                <div class="mb-1 cs-checkbox min-checkbox">
                  <input v-model="form.relapse" id="relapse" name="relapse" type="checkbox" />
                  <label>Relapse/ <i>Tái phát:</i></label>
                </div>
              </div>
              <div class="cs-flex">
                <span class="cs-label">Stage/ <i>Giai đoạn tái phát</i></span>
                <span class="cs-content" v-html="form.stage_relapse"></span>
              </div>
            </div>
          </div>
          <!-- PATIENT INFORMATION STAGE -->
          <!-- HAEMATOLOGICAL TUMOUR TYPE -->
          <div>
            <span class="cs-title cs-border-b text-center">HAEMATOLOGICAL TUMOUR TYPE/ <i>Loại khối u huyết học</i></span>
            <div class="cs-border-b">
              <div class="cs-flex">
                <span class="cs-label">Type/ <i>Loại</i></span>
                <div class="d-flex flex-column cs-content">
                  <div class="cs-flex cs-content">
                    <div
                      class="cs-checkbox min-checkbox"
                      :set="isAML =form.haematological_tumour_type.some(item=>item ===1)"
                    >
                      <input v-model="isAML" id="AML" name="AML" type="checkbox" />
                      <label>AML</label>
                    </div>
                    <div
                      class="cs-checkbox min-checkbox"
                      :set="isMDS =form.haematological_tumour_type.some(item=>item ===2)"
                    >
                      <input v-model="isMDS" id="MDS" name="MDS" type="checkbox" />
                      <label>MDS</label>
                    </div>
                    <div
                      class="cs-checkbox"
                      :set="isLymphoma =form.haematological_tumour_type.some(item=>item ===3)"
                    >
                      <input v-model="isLymphoma" id="Lymphoma" name="Lymphoma" type="checkbox" />
                      <label>Lymphoma/ <i>Ung thư hạch</i></label>
                    </div>
                  </div>
                  <div class="cs-flex cs-content">
                    <div
                      class="cs-checkbox min-checkbox"
                      :set="isCLL =form.haematological_tumour_type.some(item=>item ===4)"
                    >
                      <input v-model="isCLL" id="CLL" name="CLL" type="checkbox" />
                      <label>CLL</label>
                    </div>
                    <div
                      class="cs-checkbox min-checkbox"
                      :set="isMPN =form.haematological_tumour_type.some(item=>item ===5)"
                    >
                      <input v-model="isMPN" id="MPN" name="MPN" type="checkbox" />
                      <label>MPN</label>
                    </div>
                    <div
                      class="cs-checkbox"
                      :set="isMyeloma =form.haematological_tumour_type.some(item=>item ===6)"
                    >
                      <input v-model="isMyeloma" id="Myeloma" name="Myeloma" type="checkbox" />
                      <label>Myeloma/ <i>Đau tủy</i></label>
                    </div>
                  </div>
                  <div class="cs-flex cs-content">
                    <div
                      class="cs-checkbox min-checkbox"
                      :set="isALL =form.haematological_tumour_type.some(item=>item ===7)"
                    >
                      <input v-model="isALL" id="ALL" name="ALL" type="checkbox" />
                      <label>ALL</label>
                    </div>
                    <div
                      class="cs-checkbox min-checkbox"
                      :set="isDLBCL =form.haematological_tumour_type.some(item=>item ===8)"
                    >
                      <input v-model="isDLBCL" id="DLBCL" name="DLBCL" type="checkbox" />
                      <label>DLBCL</label>
                    </div>
                  </div>
                  <div
                    class="cs-flex cs-checkbox"
                    :set="isOthersHaematological =form.haematological_tumour_type.some(item=>item ===0)"
                  >
                    <input
                      v-model="isOthersHaematological"
                      id="TUMOUR-other"
                      name="TUMOUR-other"
                      type="checkbox"
                    />
                    <label>Others/ <i>Khác</i></label>
                  </div>
                </div>
              </div>
              <span
                class="cs-flex text-center justify-content-center"
              >Please include most recent copy of pathology report and CBC
              <br><i>Vui lòng bao gồm bản sao gần đây nhất của báo cáo về bệnh lý và CBC</i></span>
            </div>
          </div>
          <!-- HAEMATOLOGICAL TUMOUR TYPE -->

          <!-- ADDITIONAL NOTES -->
          <div>
            <span class="cs-title cs-border-b text-center">ADDITIONAL NOTES/ <i>Lưu ý bổ sung</i></span>
            <span class="box-content cs-content" v-html="form.additional_notes"></span>
          </div>
          <!-- ADDITIONAL NOTES -->

          <!-- ADDITIONAL NOTES -->
          <div>
            <span class="cs-title cs-border-b cs-border-t">TEST SELECTION/ <i>Lựa chọn xét nghiệm</i></span>
            <b-row class="mt-1 mb-1 mx-0">
              <b-col class="text-center" cols="7">
                <strong>Test/ <i>Xét nghiệm</i></strong>
              </b-col>
              <b-col class="text-center" cols="3">
                <strong>Type/ <i>Loại</i></strong>
              </b-col>
              <b-col class="text-center" cols="2">
                <strong>Genes/ <i>Gen</i></strong>
              </b-col>
            </b-row>
            <b-row v-for="(testInfo,index) in form.test_selection" :key="index" class="mt-1 px-1">
              <b-col cols="7" v-if="testInfo">
                <div class="cs-flex cs-checkbox py-0">
                  <input
                    v-model="testInfo.product_name"
                    id="Screening"
                    name="Screening"
                    type="checkbox"
                  />
                  <label class="cs-content">
                    <strong>{{testInfo.product_name}}</strong>
                  </label>
                </div>
              </b-col>
              <b-col class="text-center" v-if="testInfo" cols="3">
                <span class="cs-content text-center" v-html="testInfo.test_type"></span>
              </b-col>
              <b-col class="text-center" v-if="testInfo" cols="2">
                <span class="cs-content text-center" v-html="testInfo.genes"></span>
              </b-col>
            </b-row>
            <!-- <b-row class="mt-1 px-1">
              <b-col cols="7">
                <div class="cs-flex cs-checkbox py-0">
                  <input v-model="a" id="Screening" name="Screening" type="checkbox" />
                  <label class="cs-content">
                    <strong>CancerScreenDx™</strong> MultiCancer Early Detection
                  </label>
                </div>
              </b-col>
              <b-col class="text-center" cols="3">
                <span class="cs-content" v-html="'DNA'"></span>
              </b-col>
              <b-col class="text-center" cols="2">
                <span class="cs-content" v-html="'164'"></span>
              </b-col>
            </b-row>
            <b-row class="mt-1 px-1">
              <b-col cols="7">
                <div class="cs-flex cs-checkbox py-0">
                  <input v-model="a" id="Screening" name="Screening" type="checkbox" />
                  <label class="cs-content">
                    <strong>OncoDx™</strong> Comprehensive Gene Profiling (CGP)
                  </label>
                </div>
              </b-col>
              <b-col class="text-center" cols="3">
                <span class="cs-content" v-html="'DNA'"></span>
              </b-col>
              <b-col class="text-center" cols="2">
                <span class="cs-content" v-html="'164'"></span>
              </b-col>
            </b-row>
            <b-row class="mt-1 px-1">
              <b-col cols="7">
                <div class="cs-flex cs-checkbox py-0">
                  <input v-model="a" id="Screening" name="Screening" type="checkbox" />
                  <label class="cs-content">
                    <strong>PreciseDx™</strong> Multimodal Comprehensive Gene Profiling (CGP)
                  </label>
                </div>
              </b-col>
              <b-col class="text-center" cols="3">
                <span class="cs-content" v-html="'DNA + RNA'"></span>
              </b-col>
              <b-col class="text-center" cols="2">
                <span class="cs-content" v-html="'523'"></span>
              </b-col>
            </b-row>
            <b-row class="mt-1 px-1">
              <b-col cols="7">
                <div class="cs-flex cs-checkbox py-0">
                  <input v-model="a" id="Screening" name="Screening" type="checkbox" />
                  <label class="cs-content">
                    <strong>LiquidDx™</strong> Liquid Biopsy (ctDNA) for Primary or Metastatic Cancers
                  </label>
                </div>
              </b-col>
              <b-col class="text-center" cols="3">
                <span class="cs-content" v-html="'DNA + RNA'"></span>
              </b-col>
              <b-col class="text-center" cols="2">
                <span class="cs-content" v-html="'523'"></span>
              </b-col>
            </b-row>
            <b-row class="mt-1 px-1">
              <b-col cols="7">
                <div class="cs-flex cs-checkbox py-0">
                  <input v-model="a" id="Screening" name="Screening" type="checkbox" />
                  <label class="cs-content">
                    <strong>CancerTRACE™</strong> Liquid Biopsy (ctDNA) for Cancer Recurrence
                  </label>
                </div>
              </b-col>
              <b-col class="text-center" cols="3">
                <span class="cs-content" v-html="'DNA + RNA'"></span>
              </b-col>
              <b-col class="text-center" cols="2">
                <span class="cs-content" v-html="'523'"></span>
              </b-col>
            </b-row>-->
            <b-row v-if="form.other_test_info.isOther" class="mt-1 px-1">
              <b-col cols="7">
                <div class="d-flex align-items-center">
                  <div class="cs-flex cs-checkbox py-0">
                    <input
                      v-model="form.other_test_info.isOther"
                      id="Others:"
                      name="Others:"
                      type="checkbox"
                    />
                    <label class="cs-content">
                      <strong>Others:/ <i>Khác (nếu có):</i></strong>
                    </label>
                  </div>
                  <span class="cs-content cs-font-bold" v-html="form.other_test_info.name"></span>
                </div>
              </b-col>
              <b-col v-if="form.other_test_info.isOther" class="text-center" cols="3">
                <span class="cs-content" v-html="form.other_test_info.test_type"></span>
              </b-col>
              <b-col v-if="form.other_test_info.isOther" class="text-center" cols="2">
                <span class="cs-content" v-html="form.other_test_info.genes"></span>
              </b-col>
            </b-row>
            <!-- <b-row v-if="form.other_test_description" class="mt-1 px-1">
              <b-col cols="3">
                <div class="cs-flex cs-checkbox py-0">
                  <input
                    v-model="form.other_test_description"
                    id="Others:"
                    name="Others:"
                    type="checkbox"
                  />
                  <label class="cs-content">
                    <strong>Others:</strong>
                  </label>
                </div>
              </b-col>
              <b-col cols="9">
                <span class="cs-content" v-html="form.other_test_description"></span>
              </b-col>
            </b-row>-->
            <!-- <b-row class="cs-flex my-1 px-1">
              <b-col cols="2">
                <div class="cs-flex cs-checkbox py-0">
                  <input v-model="a" id="Screening" name="Screening" type="checkbox" />
                  <label class="cs-content">Others:</label>
                </div>
              </b-col>
              <b-col cols="10" class="cs-content">
                <span class="cs-content" v-html="form.physician_name"></span>
              </b-col>
            </b-row>-->
          </div>
          <!-- ADDITIONAL NOTES -->
        </b-col>

        <!-- COLUMN RIGHT -->
      </b-row>
      <!-- PAGE 1 -->
      <div class="html2pdf__page-break"></div>

      <!-- PAGE 2 -->
      <b-row>
        <div class="container-border" style="width:100%; margin-top:100px">
          <span
            class="cs-title cs-border-b text-center"
            style="text-transform:capitalize"
          >Sample Collection Instruction/ <i>Hướng dẫn lấy mẫu</i></span>
          <div class="cs-border-b cs-padding">
            <div>All clinical materials should be collected with approved methods to avoid contamination and cross contamination.<br> <i>Tất cả các vật liệu lâm sàng phải được thu thập bằng các phương pháp đã được phê duyệt để tránh nhiễm bẩn và nhiễm chéo.</i></div>
            <div class="mt-1">A sterile environment must be maintained when collecting samples.<br> <i>Môi trường vô trùng phải được duy trì khi lấy mẫu.</i></div>
            <div class="mt-1">Our sample preparation requirements are detailed in the table below:<br> <i>Yêu cầu chuẩn bị mẫu của chúng tôi được trình bày chi tiết trong bảng dưới:</i></div>
          </div>
          <div>
            <div class="d-flex">
              <div class="cs-border-r p-0 col-8">
                <span
                  class="cs-title cs-border-b"
                  style="text-transform:capitalize"
                >Sample Requirement According to Test/ <i>Yêu cầu của mẫu theo từng xét nghiệm</i></span>
                <div class="cs-border-b">
                  <div class="cs-flex">
                    <div class="col-4 text-center">
                      <strong>Test/ <i>Xét nghiệm</i></strong>
                    </div>
                    <div class="col-3 text-center">
                      <strong>Sample Type/ <i>Loại mẫu</i></strong>
                    </div>
                    <div class="col-5 text-center">
                      <strong>Volume/ <i>Dung tích</i></strong>
                    </div>
                  </div>
                </div>
                <!-- <div
                  v-for="(sampleRequirementItem,index) in form.sample_requirement"
                  :key="index"
                  class
                  style="min-height:200px"
                >
                  <div class="cs-flex">
                    <div class="col-4 cs-font-bold">{{ sampleRequirementItem.product_name }}</div>
                    <div class="col-3"></div>
                    <div v-if="sampleRequirementItem.volume" class="col-5"></div>
                  </div>
                </div>-->
                <div class="cs-border-b">
                  <div class="cs-flex">
                    <div class="col-4 cs-font-bold">CancerScreenDx™</div>
                    <div class="col-3">
                      <div>Blood in EDTA<br><i>Máu toàn phần chống đông EDTA</i></div>
                      <div>Saliva Swab<br><i>Bộ xét nghiệm</i></div>
                      <div>Buccal Swab<br><i>Mẫu khoang miệng quét bằng tăm bông</i></div>
                    </div>
                    <div class="col-5">
                      <div>20mL</div>
                      <div>1x Oragene™ OG-610 saliva kit<br><i>1x Bộ xét nghiệm nhanh nước</i></div>
                      <div>1x Oragene™ OCR-100<br><i>1x Bộ lấy mẫu xét nghiệm nước bọt bằng tăm bông™</i></div>
                    </div>
                  </div>
                </div>
                <div class="cs-border-b">
                  <div class="cs-flex">
                    <div class="col-4 cs-font-bold">CancerScreenDx™</div>
                    <div class="col-3">
                      <span>
                        Blood in EDTA or
                        cfDNA Collection
                        Tube<br><i>Máu toàn phần chống đông EDTA hoặc ống thu mẫu cfDNA</i>
                      </span>
                    </div>
                    <div class="col-5">
                      <span>20mL</span>
                    </div>
                  </div>
                </div>
                <div class="cs-border-b">
                  <div class="cs-flex">
                    <div class="col-4 cs-font-bold">OncoDx™</div>
                    <div class="col-3">
                      <span>
                        FFPE (including core
                        needle or fine needle
                        biopsies)
                        <br><i>FFPE (bao gồm Sinh thiết lõi hoặc sinh thiết kim nhỏ)</i>
                      </span>
                    </div>
                    <div class="col-5">
                      <span>
                        4 sections, each with a thickness
                        of 10 µm.
                        <br><i>4 lát cắt, mỗi phần có độ dày 10 pm.</i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="cs-border-b">
                  <div class="cs-flex">
                    <div class="col-4 cs-font-bold">PreciseDx™ (CGP)</div>
                    <div class="col-3">
                      <span>
                        FFPE (including core
                        needle or fine needle
                        biopsies)
                        <br><i>FFPE (bao gồm Sinh thiết lõi hoặc sinh thiết kim nhỏ)</i>
                      </span>
                    </div>
                    <div class="col-5">
                      <span>
                        4 sections, each with a thickness
                        of 10 µm.
                        <br><i>4 lát cắt, mỗi phần có độ dày 10 pm.</i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="cs-border-b">
                  <div class="cs-flex">
                    <div class="col-4 cs-font-bold">LiquidDx™</div>
                    <div class="col-3">
                      <span>
                        Blood in EDTA or
                        cfDNA Collection
                        Tube
                        <br><i>Máu toàn phần chống đông EDTA hoặc ống thu mẫu cfDNA</i>
                      </span>
                    </div>
                    <div class="col-5">
                      <span>20mL</span>
                    </div>
                  </div>
                </div>
                <div class>
                  <div class="cs-flex">
                    <div class="col-4 cs-font-bold">CancerTRACE™</div>
                    <div class="col-3">
                      <span>
                        Blood in EDTA or
                        cfDNA Collection
                        Tube
                        <br><i>Máu toàn phần chống đông EDTA hoặc ống thu mẫu cfDNA</i>
                      </span>
                    </div>
                    <div class="col-5">
                      <span>20mL</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-4 cs-padding">
                <div class="cs-title p-0" style="text-transform:capitalize">Shipping Instructions/ <i>Hướng dẫn giao mẫu</i></div>
                <!-- <span class="mt-1" v-html="form.shipping_instructions"></span> -->
                <div class="mt-1">Sample should be sent to:<br><i>Mẫu nên được gửi đến:</i></div>
                <div class="mt-1">
                  AGTC Genomics Sdn Bhd (1428365-D)
                  J2-1, Pusat Perdagangan Bukit Jalil,
                  Jalan Persiaran Jalil 1,
                  Bukit Jalil,
                  57000 Kuala Lumpur,
                  Malaysia
                </div>
                <div class="mt-2">
                  EDTA Blood, buccal swab, and saliva samples can
                  be sent via regular despatch at room temperature.
                  <br>
                  <i>Máu toàn phần chống đông EDTA, Mẫu tế bào bong khoang
                  miệng hoặc mẫu nước bọt có thể được gửi qua đường gửi
                  thông thường ở nhiệt độ phòng.</i>
                </div>
                <div class="mt-1">
                  Liquid blood is stable for up to 4 days during
                  transport
                  <br>
                  <i>Máu ổn định đến 4 ngày trong quá trình vận chuyển.</i>
                </div>
                <div class="mt-1">
                  FFPE and DNA/RNA samples can be sent via
                  regular despatch at room temperature.
                  <br>
                  <i>Các mẫu FFPE và DNA/RNA có thể được gửi qua đường gửi thường xuyên ở nhiệt độ phòng.</i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-none mt-5 mb-3"></div>
        <!-- FOR OFFICE USE -->
        <div class="cs-width-full">
          <span class="cs-title">FOR OFFICE USE/ <i>DÙNG CHO VĂN PHÒNG</i></span>
          <div class="d-flex container-border cs-width-full mt-1">
            <div class="col-6 cs-border-r p-0">
              <div class="p-1 cs-border-b">Reviewed by Authorised Laboratory Staff<br><i>Được đánh giá bởi nhân viên phòng thí nghiệm được ủy quyền</i></div>
              <div class="d-flex cs-border-b">
                <div class="col-8 cs-border-r p-1" v-html="form.office_content"></div>
                <div class="col-4 p-1">
                  <div class="d-flex flex-column cs-content">
                    <div class="cs-checkbox" :set="isAcceptOffice =form.authorised_status===1">
                      <input v-model="isAcceptOffice" id="Accept" name="Accept" type="checkbox" />
                      <label>Accept/ <i>Chấp nhận</i></label>
                    </div>
                    <div class="cs-checkbox mt-1" :set="isRejectOffice =form.authorised_status===2">
                      <input v-model="isRejectOffice" id="Reject" name="Reject" type="checkbox" />
                      <label>Reject/ <i>Từ chối</i></label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex cs-border-b">
                <div class="col-2 p-1">Name/ <i>Tên</i></div>
                <div class="col-10 cs-border-l p-1" v-html="form.authorised_name"></div>
              </div>
              <div class="d-flex cs-border-b">
                <div class="col-2 p-1">Date/ <i>Ngày</i></div>
                <div class="col-10 cs-border-l p-1" v-html="handleFormatDate(form.authorised_date)"></div>
              </div>
              <div class="d-flex cs-border-b">
                <div class="p-1">Reason for rejecting Request Form/ <i>Lý do từ chối Phiếu yêu cầu</i></div>
              </div>
              <div class="d-flex">
                <div class="p-1" style="min-height:60px" v-html="form.authorised_reject_reason"></div>
              </div>
            </div>
            <div class="col-6 p-0">
              <div class="p-1 cs-border-b">Reviewed by Marketing Personnel<br><i>Đánh giá bởi Nhân viên tiếp thị</i></div>
              <div class="d-flex cs-border-b">
                <div class="p-1" style="min-height:83px" v-html="form.marketing_content"></div>
              </div>
              <div class="d-flex cs-border-b">
                <div class="col-2 p-1">Name/ <i>Tên</i></div>
                <div class="col-10 cs-border-l p-1" v-html="form.marketing_name"></div>
              </div>
              <div class="d-flex cs-border-b">
                <div class="col-2 p-1">Date/ <i>Ngày</i></div>
                <div class="col-10 cs-border-l p-1" v-html="handleFormatDate(form.marketing_date)"></div>
              </div>
              <div class="d-flex cs-border-b">
                <div class="p-1">Remark (if any)/ <i>Nhận xét (nếu có)</i></div>
              </div>
              <div class="d-flex">
                <div class="p-1" style="min-height:60px" v-html="form.marketing_remark"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- FOR OFFICE USE -->
      </b-row>
      <!-- PAGE 2 -->
      <div class="html2pdf__page-break"></div>
      <!-- PAGE 3 -->
      <b-row style="width:100%; margin-top:70px">
        <div class="d-flex align-items-center justify-content-between cs-width-full">
          <div class="col-4 p-0">
            <span class="cs-text-sm">Patient Name/ <i>Tên bệnh</i></span>
            <div v-html="form.patient_name" style="min-height:40px"></div>
          </div>
          <div class="col-4 p-0"></div>
          <div class="col-4 p-0">
            <span class="cs-text-sm">Patient ID/ <i>ID bệnh nhân</i></span>
            <div v-html="form.patient_id" style="min-height:40px"></div>
          </div>
        </div>
        <div class="mt-3">
          <span>I understand the following:<br><i>Tôi hiểu như sau:</i></span>
          <div class="mt-2">
            <ul class="cs-list">
              <li class="col-3">
                A POSITIVE result is an indication that I may be
                predisposed to or have the specific disease, or
                condition. Further testing may be needed to confirm
                the diagnosis.
                <br><i>Kết quả DƯƠNG TÍNH là dấu hiệu cho thấy tôi có thể là người dễ mắc
                hoặc mắc một bệnh cụ thể, hoặc những hạn chế về tình trạng bệnh của
                mình. Có thể cần phải xét nghiệm thêm để xác nhận một số thay đổi
                trong chẩn đoán</i>
              </li>
              <li class="col-5">
                There is a chance that I will have this genetic condition but that the
                genetic test results will be NEGATIVE. Due to limitations in the
                technology and incomplete knowledge of genes, some changes in
                DNA or protein products that cause disease, may not be detected by
                the test.
                <br><i>Có khả năng tôi sẽ mắc phải tình trạng di truyền này, kết quả xét nghiệm di truyền sẽ là
                ÂM TÍNH. Do công nghệ và kiến thức chưa được trang bị đủ về gen, DNA hoặc các sản
                phẩm protein gây bệnh, xét nghiệm có thể không phát hiện được.</i>
              </li>
              <li class="col-4">
                There may be a possibility that the laboratory findings will
                be UNINTERPRETABLE or of unknown significance. In
                rare circumstances, findings may be suggestive of a condition
                different than the diagnosis that was originally considered.
                <br><i>Các phát hiện trong phòng thí nghiệm có khả năng KHÔNG THỂ GIẢI THÍCH
                được hoặc không có ý nghĩa quan trọng. Trong một số ít trường hợp, các phát
                hiện gợi ý về một tình trạng khác với chẩn đoán đã được đánh giá ban đầu.</i>
              </li>
            </ul>
          </div>
          <div class="mt-3">
            <ol class="cs-ol" type="1">
              <li>
                The potential benefit of this test is to confirm the diagnosis of the condition and to determine which other family members may be carriers or have increased
                risk of having the defective gene.
                <br><i>Lợi ích tiềm năng của xét nghiệm này là xác nhận chẩn đoán tình trạng và xác định thành viên nào khác trong gia đình của bạn là người mang mầm bệnh hoặc có nguy cơ cao mang gen khiếm khuyết.</i>
              </li>
              <li>
                Erroneous results and incorrect interpretation may occur because of rare variation in the DNA of the individual, rare technical error, disincorporation of DNA
                bases by the enzyme used to perform the test, sample misidentification, sample contamination, primer site mutations and general laboratory error.
                <br><i>Kết quả sai và giải thích sai có thể xảy ra do sự biến đổi hiếm gặp trong DNA của từng cá nhân mỗi người, lỗi kỹ thuật hiếm gặp, sự không hợp nhất của các bazơ DNA bởi enzyme được sử dụng để thực hiện xét nghiệm, xác định sai mẫu, mẫu nhiễm bẩn,
                đột biến vị trí nhất định và lỗi chung của phòng thí nghiệm.
                </i>
              </li>
              <li>
                Accurate interpretation of the DNA test result depends on correct information about the clinical diagnosis and about the biological relationships within the
                family.
                <br><i>Việc giải thích chính xác kết quả xét nghiệm DNA phụ thuộc vào thông tin chính xác về chẩn đoán lâm sàng và về mối quan hệ sinh học trong gia đình.</i>
              </li>
              <li>DNA testing may reveal non-paternity, meaning that the stated father is not the biological father.
                <br><i>Xét nghiệm DNA có thể tiết lộ quan hệ không phải cha con ruột thịt, nghĩa là người cha đã nêu không phải là cha ruột.</i>
              </li>
              <li>
                The tests offered are considered to be the best available at this time. If technology improves and more mutations (gene defects) are detectable in future, I
                authorize the Laboratory to re-analyse, at the Laboratory’s option, any remaining DNA for the same disease without being informed. If the sample is
                insufficient, my doctor may ask me for a fresh sample. There may be additional fees for such tests.
                <br><i>Các xét nghiệm được cung cấp được coi là tốt nhất hiện có tại thời điểm này. Nếu công nghệ được cải thiện và có thể phát hiện thêm nhiều đột biến (khiếm khuyết gen) trong tương lai, tôi muốn ủy quyền vấn đề này cho Phòng thí nghiệm phân tích lại,
                theo lựa chọn của Phòng thí nghiệm, bất kỳ DNA nào còn lại cho cùng một căn bệnh mà không cần phải thông báo. Nếu mẫu không đủ, bác sĩ có thể yêu cầu tôi lấy mẫu mới. Có thể sẽ có phí bổ sung cho các xét nghiệm như vậy.
                </i>
              </li>
              <li>In order to help me understand the test results, the results will be reported to me only through a doctor or genetic counsellor.
                <br><i>Để giúp tôi hiểu rõ kết quả xét nghiệm của abnr thân, kết quả sẽ chỉ được thông báo cho tôi thông qua bác sĩ hoặc chuyên gia tư vấn về di truyền.</i>
              </li>
              <li>This report shall be used ONLY for clinical interpretation.
                <br><i>Báo cáo này CHỈ được sử dụng để giải thích lâm sàng.</i>
              </li>
              <li>This report shall NOT be used for any forensic purposes or is NOT VALID for forensic interpretation.
                <br><i>Báo cáo này sẽ KHÔNG được sử dụng cho bất kỳ mục đích phát hiện nào hoặc việc giải thích về phát hiện KHÔNG HỢP LỆ</i>
              </li>
              <li>This report shall NOT be used in any courts of law or in legal matters and is NOT VALID for legal interpretation.
                <br><i>Báo cáo này sẽ KHÔNG được sử dụng trong bất kỳ tòa án nào của pháp luật hoặc trong các vấn đề pháp lý và việc giải thích pháp lý KHÔNG HỢP LỆ.</i>
              </li>
            </ol>
          </div>
        </div>
        <div class="mt-2 cs-width-full">
          <span>For prenatal testing, the following also apply:
            <br>
            <i>Đối với xét nghiệm tiền sản, những điều sau đây cũng được áp dụng:</i>
          </span>
          <ol class="cs-ol mt-1">
            <li>This DNA test will determine the status of the fetus for this disease ONLY.
              <br>
              <i>Xét nghiệm DNA này sẽ là xét nghiệm xác định DUY NHẤT tình trạng của thai nhi đối với căn bệnh này.</i>
            </li>
            <li>Besides rare DNA variation and the technical error, erroneous results may also arise from maternal contamination of the fetal sample.
              <br>
              <i>Bên cạnh biến thể DNA hiếm gặp và lỗi kỹ thuật, kết quả sai cũng có thể phát sinh do mẫu thai nhi bị nhiễm bẩn.</i>
            </li>
          </ol>
        </div>
        <div class="mt-2">
          <span class="cs-title">WRITTEN CONSENT:</span>
          <ol class="cs-ol mt-2">
            <li>A biologic specimen (blood, tissue, amniotic fluid, or chorionic villi) will be collected for DNA tests for the above condition.
              <br>
              <i>Một mẫu sinh học (máu, mô, nước ối hoặc sinh thiết gai nhau ) sẽ được thu thập để xét nghiệm ADN cho tình trạng trên.</i>
            </li>
            <li>
              After DNA testing is completed, a small amount of my DNA may be made anonymous and used for medical education, quality control or research. Since the
              samples have been anonymised, any research results obtained cannot be reported to me. I understand that any biologic specimens obtained for the purpose of this
              genetic testing become the exclusive property of AGTC Genomics Sdn. Bhd. After the specific test(s) requested have been completed, the laboratory may
              dispose, retain, or use the de-identified specimen(s) for test validation or education; i.e publication into journals. I understand that my identity will be protected.
              <br>
              <i>Sau khi xét nghiệm DNA hoàn tất, một lượng nhỏ DNA của tôi có thể được bảo mật và được sử dụng cho các mục đích giáo dục y tế, kiểm soát chất lượng hoặc nghiên cứu. Vì các mẫu đã được bảo mật nên không thể báo cáo bất kỳ kết quả nghiên cứu nào thu
              được cho tôi. Tôi hiểu rằng bất kỳ mẫu sinh học nào thu được cho mục đích xét nghiệm di truyền này đều trở thành tài sản độc quyền của AGTC Genomics Sdn. Bhd. Sau khi (các) xét nghiệm cụ thể được yêu cầu đã được hoàn thành, phòng thí nghiệm có thể bỏ
              đi, giữ lại hoặc sử dụng (các) mẫu không xác định để kiểm chứng xét nghiệm hoặc giáo dục; tức là công bố trên các tạp chí. Tôi hiểu rằng danh tính của tôi sẽ được bảo vệ.
              </i>
            </li>
            <li>DNA results are strictly CONFIDENTIAL and will not be released to anyone including my relatives/ other than my doctors without my consent.
              <br>
              <i>Kết quả DNA được BẢO MẬT một cách nghiêm ngặt và sẽ không được tiết lộ cho bất kỳ ai kể cả người thân /ngoài bác sĩ của tôi mà không có sự đồng ý của tôi.</i>
            </li>
          </ol>
        </div>
        <div class="mt-2 cs-width-full">
          <div class="container-border cs-width-full">
            <span class="cs-text-sm cs-border-b cs-flex">To be completed by:/ <i>Được hoàn thành bởi:</i></span>
            <div class="d-flex">
              <div class="col-6 p-0 cs-border-r">
                <div class="cs-flex cs-border-b">
                  <span class="cs-text-md">PATIENT/ PARENT/ LEGAL GUARDIAN<br><i>BỆNH NHÂN/CHA MẸ/NGƯỜI GIÁM HỘ HỢP PHÁP</i></span>
                  <span class="cs-content ml-1" v-html="form.legal_guardian_name"></span>
                </div>
                <div class="cs-flex flex-column align-items-start">
                  <div class="cs-text-sm" style="min-height:160px">
                    I have read and received a copy of this consent form. I understand the information provided in this
                    document and I have had the opportunity to ask questions about testing, the procedure and the associated
                    risks, benefits and limitations. I agree to have genetic testing and accept the risks and limitations.
                    <br>
                    <i>Tôi đã đọc và nhận được một bản sao của mẫu chấp thuận này. Tôi hiểu rõ các thông tin được cung cấp trong tài liệu này và tôi đã
                    có cơ hội đặt câu hỏi về xét nghiệm, quy trình cũng như các rủi ro, lợi ích và hạn chế liên quan. Tôi đồng ý thực hiện xét nghiệm di
                    truyền và chấp nhận những rủi ro và hạn chế.
                    </i>
                  </div>
                  <div style="min-height:60px">
                    <span>SIGNATURE:/ <i>CHỮ KÝ:</i></span>
                    <div class="cs-content" v-html="form.legal_guardian_signature"></div>
                  </div>
                  <div>
                    <span>NAME & IC NO.:/ <i> TÊN VÀ SỐ IC:</i></span>
                    <span class="cs-content ml-1" v-html="form.legal_guardian_ic"></span>
                  </div>
                  <div>
                    <span>DATE:/ <i>NGÀY:</i></span>
                    <span
                      class="cs-content ml-1"
                      v-html="handleFormatDate(form.legal_guardian_date)"
                    ></span>
                  </div>
                </div>
              </div>
              <div class="col-6 p-0">
                <div class="cs-flex cs-border-b">
                  <span class="cs-text-md">DOCTOR/ COUNSELLOR<br> <i>BÁC SĨ/NHÂN VIÊN TƯ VẤN</i></span>
                  <span class="cs-content ml-1" v-html="form.counsellor_name"></span>
                </div>
                <div class="cs-flex flex-column align-items-start">
                  <div
                    class="cs-text-sm"
                    style="min-height:160px"
                  >I have fully explained the nature of the requested test(s) to the patient/ parent/ legal guardian.
                    <br>
                    <i>Tôi đã giải thích đầy đủ bản chất của (các) xét nghiệm được yêu cầu cho bệnh nhân/cha mẹ/
                    người giám hộ hợp pháp của mình.
                    </i>
                  </div>
                  <div style="min-height:60px">
                    <span>SIGNATURE & OFFICIAL STAMP:/ <i>CHỮ KÝ VÀ CON DẤU CHÍNH THỨC:</i></span>
                    <div class="cs-content" v-html="form.counsellor_signature"></div>
                  </div>
                  <div>
                    <span>NAME:/ <i>TÊN:</i></span>
                    <span class="cs-content ml-1" v-html="form.counsellor_name_2"></span>
                  </div>
                  <div>
                    <span>DATE:/ <i>NGÀY:</i></span>
                    <span class="cs-content ml-1" v-html="handleFormatDate(form.counsellor_date)"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-row>
      <!-- PAGE 3 -->
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/@core/utils/filter";
import { mapState } from "vuex";

const DEFAULT_FORM = {
  full_name: "",
  identity_card_number: "",
  medical_record_no: null,
  gender: null,
  urgency: null,
  other_instruction: "",
  physician_name: "",
  contact_number: null,
  email: "",
  nsr_no: null,
  acc_name: "",
  acc_contact_no: null,
  acc_address: "",
  acc_email: "",
  paid_by: null,
  billed_to: "",
  bill_acc_no: null,
  bill_address: "",
  bill_phone: null,
  bill_email: "",
  sample_collection_date_time: null,
  sample_id: null,
  sample_type: null,
  fixative: null,
  other_fixative: "",
  body_site: "",
  official_stamp: "",
  solid_tumour_type: [],
  solid_tumour_stage: null,
  stage_relapse: "",
  haematological_tumour_type: [],
  additional_notes: "",
  test_selection: [],
  other_test_description: "",
  other_test_info: {},
  authorised_content: "",
  authorised_status: null,
  authorised_name: "",
  authorised_date: null,
  authorised_reject_reason: "",
  marketing_content: "",
  marketing_name: "",
  marketing_date: null,
  marketing_remark: "",
  patient_name: "",
  patient_id: null,
  completed_by: "",
  legal_guardian_name: "",
  legal_guardian_ic: "",
  legal_guardian_date: null,
  legal_guardian_signature: "",
  legal_guardian_confirm: "",
  counsellor_name: "",
  counsellor_name_2: "",
  counsellor_date: "",
  counsellor_signature: "",
  counsellor_confirm: "",
  list_primary: "",
  relapse: false,
  sample_requirement: [],
  shipping_instructions: "",
  office_content: ""
};

export default {
  name: "PrintRequisitionForm",

  data() {
    return {
      form: { ...DEFAULT_FORM },
      DEFAULT_FORM
    };
  },
  computed: {
    ...mapState({
      orders: state => state.orders
    })
  },
  watch: {
    $route() {
      if (this.$route.params.id) {
        this.handleMapData();
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.handleMapData();
    }
  },
  methods: {
    handlePrint() {
      document.title = "Requisition-form";
      window.print();
    },
    handleMapData() {
      this.form = this.orders.requisitionForm || { ...DEFAULT_FORM };
    },
    backToDetail() {
      this.$router.replace({
        name: "orderDetail",
        params: {
          id: this.orders?.viewDetail?.id
        },
        query: {
          step: 0
        }
      });
    },
    handleFormatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    }
  }
};
</script>

<style lang="scss" scoped>
.cs-container {
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;

  * {
    color: black;
    font-family: "Times New Roman", "Tinos", Times, serif !important;
  }

  #printOrderForm {
    margin: auto;
    // margin-top: 100px;
    padding-left: 20px;
    padding-right: 20px;
  }

  h2 {
    // color: black;
    font-size: 28px;
  }
}

.container-border {
  border: 1px solid #5c5c5c;
  padding: 0;
  width: 50%;
}

.cs-title {
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 6px;
}

.cs-flex {
  display: flex;
  gap: 8px;
  padding: 6px;
  box-sizing: border-box;
  align-items: center;
}

.cs-label {
  width: 150px;
}

.cs-border-b {
  border-bottom: 1px solid #5c5c5c;
}
.cs-border-t {
  border-top: 1px solid #5c5c5c;
}

.cs-border-l {
  border-left: 1px solid #5c5c5c;
}

.cs-border-r {
  border-right: 1px solid #5c5c5c;
}

.cs-width-full {
  width: 100% !important;
}

.cs-font-bold {
  font-weight: bold;
}

.cs-checkbox {
  pointer-events: none;
  display: flex;
  align-items: center;

  input {
    width: 18px;
    height: 18px;
  }

  input[type="checkbox"] {
    accent-color: #20419b;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    margin-left: 6px;
  }
}

.cs-content {
  flex: 1;
}

.min-checkbox {
  min-width: 120px;
}
.box-content {
  display: block;
  padding: 6px;
  min-height: 120px;
}
.html2pdf__page-break {
  page-break-before: always;
}

.cs-padding {
  padding: 6px;
}

.cs-border {
  border: 1px solid #5c5c5c;
}

.content-none {
  width: 100%;
  border-bottom: 2px dashed;
}

.cs-text-sm {
  font-size: 11px;
  font-weight: 500;
}

.cs-text-md {
  font-size: 16px;
}

.cs-list {
  display: flex;
  gap: 12px;
}

.cs-ol {
  li {
    position: relative;
    padding-left: 10px;
    margin-left: 15px;
  }

  li:before {
    position: absolute;
    left: -2px;
  }
}

.close-icon {
  position: absolute;
  top: 10px;
  right: 20px;
  color: black;
  font-size: 40px;
  cursor: pointer;
}

.cs-img {
  width: 300px;
}

.title-color {
  color: rgb(196, 91, 19);
  font-size: 36px !important;
}

@media print {
  body,
  html {
    height: 100vh;
  }

  .hidden-print {
    border: none;
    outline: none;
    background: transparent;
  }

  #BrowserPrintDefaults {
    display: none;
  }

  #printOrderForm {
    max-width: 1000px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .html2pdf__page-break {
    page-break-before: always;
  }

  @page {
    margin: 0;
  }
}
</style>